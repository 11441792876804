import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";

import "./style.css";
import { DeleteOutlined } from "@ant-design/icons";
import { Badge, Drawer } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import useWindowDimensions from "../getwidthHeight";
import { useDispatch } from "react-redux";
import { excludeProductsState } from "../../store/profile";
import Logoali from "../../image/JL LOGISTIC-26.png";
function NavbarComponent() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { products } = useSelector((state) => state.profile);
  const [open, setOpen] = useState(false);
  // const showDrawer = () => {
  //   setOpen(true);
  // };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{
          backgroundColor: "white",
          color: "#112C4B",
          marginTop: "0.5rem",
        }}
        variant="light"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={Logoali}
              style={{ width: "12rem", marginTop: "-1rem" }}
              alt="footerlogo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link>
                <Link to="/">
                  <p
                    style={{
                      color: "#184A88",
                      fontSize: "17px",
                      fontWeight: 600,
                    }}
                  >
                    HOME
                  </p>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Dropdown as={ButtonGroup}>
                  <Link to="/nostros">
                    <p
                      style={{
                        color: "#184A88",
                        fontSize: "17px",
                        fontWeight: 600,
                      }}
                    >
                      NOSOTROS
                    </p>
                  </Link>

                  <Dropdown.Toggle
                    split
                    variant="transparent"
                    id="dropdown-split-basic"
                  />

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <Link to="/vision">
                        <p>MISION Y VISION</p>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Link>

              <Nav.Link>
                <Dropdown as={ButtonGroup}>
                  <Link to="/politicas">
                    <div style={{ lineHeight: width < 1450 ? "15px" : "5px" }}>
                      <p
                        style={{
                          color: "#184A88",
                          fontSize: "17px",
                          fontWeight: 600,
                        }}
                      >
                        POLÍTICAS
                      </p>
                      <p
                        style={{
                          color: "#184A88",
                          fontSize: "17px",
                          fontWeight: 600,
                          marginTop: width < 1450 ? "-1rem" : "0",
                        }}
                      >
                        DE GESTIÓN
                      </p>
                    </div>
                  </Link>

                  <Dropdown.Toggle
                    split
                    variant="transparent"
                    id="dropdown-split-basic"
                  />

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <Link to="/CERTIFICACIONES">
                        <p>CERTIFICACIONES</p>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Link>

              <NavDropdown title="RENTA" id="collasible-nav-dropdown">
                <NavDropdown.Item>
                  <DropdownSubmenu title="MAQUINARIA PESADA">
                    <NavDropdown.Item>
                      <Link to="/maquinaria">CATALOGO</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/Excavadora">EXCAVADORAS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/retroexcavadors">RETROEXCAVADORAS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/MOTOCONFORMADORAS">MOTOCONFORMADORAS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/VIBROCOMPACTADORES">VIBROCOMPACTADORES</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/TRACTORES-BULLDOZERS">
                        TRACTORES BULLDOZERS
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/pavimentadoras">TREN DE PAVIMENTACIÓN</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/cragdor">CARGADOR FRONTAL</Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <DropdownSubmenu title="EQUIPOS TRANSPORTE">
                    <NavDropdown.Item>
                      <Link to="/machinery-transportation">CATALOGO</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/TRACTOCAMIONES">TRACTOCAMIONES</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/GONDOLAS">GONDOLAS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/LOW-BOY">LOW BOY</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/VEHICULOS">VEHICULOS UTILITARIOS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/PIPAS">PIPAS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/GruaArticulada">GRUA ARTICULADA</Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <DropdownSubmenu title="ACCESORIOS TRITURACION Y DEMOLICION">
                    <NavDropdown.Item>
                      <Link to="/ACCESORIOS">CATALOGO</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/Martillos">MARTILLOS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/TRITURADORAS">TRITURADORAS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/CRIBAS">CRIBAS</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/PINZA">PINZAS</Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/pavimentadoras" style={{ marginLeft: "1rem" }}>
                    TREN PAVIMENTACION
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="SERVICIOS" id="collasible-nav-dropdown">
                <NavDropdown.Item style={{ padding: "0" }}>
                  <DropdownSubmenu title="TRANSPORTE">
                    <NavDropdown.Item>
                      <Link to="/TransporteMaquinariapesada">
                        MAQUINARIA PESADA
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/venta">AGREGADOS PETREOS</Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/venta">VENTA AGREGADOS PETREOS</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/residuosPeligrosos">
                    TRANSPORTE RESIDUOS PELIGROSOS Y RME
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link>
                <Link to="/contact">
                  <p
                    style={{
                      color: "#184A88",
                      fontSize: "17px",
                      fontWeight: 600,
                    }}
                  >
                    CONTACTO
                  </p>
                </Link>
              </Nav.Link>

              <Nav.Link>
                <Badge count={products?.length}>
                  <p
                    className="cotizaro"
                    style={{
                      backgroundColor: "#184A88",
                      color: "white",
                      fontSize: "17px",
                      fontWeight: 600,
                      padding: "0.3rem 1rem 0.3rem 1rem",

                      borderRadius: "15px",
                    }}
                  >
                    <Link to="/cotizar">COTIZAR</Link>
                  </p>
                </Badge>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Drawer
        title="ACOTIZACIÓN"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <>
          {products?.map((item, index) => (
            <div key={index} className="oneitem">
              <div>
                <p style={{ fontSize: "18px", fontWeight: 700 }}>
                  {item?.class}
                </p>
                <p style={{ fontSize: "12px", fontWeight: 400 }}>
                  {item?.name}
                </p>
              </div>
              <div>
                <DeleteOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() =>
                    dispatch(
                      excludeProductsState({
                        name: item?.name,
                        products: products,
                      })
                    )
                  }
                />
              </div>
            </div>
          ))}
          <Link
            style={{
              backgroundColor: "green",
              color: "green",
              padding: "1rem",
              position: "absolute",
              zIndex: "5",
              bottom: 0,
              right: 0,
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "800",
            }}
            to="/ACOTIZACIÓN"
          >
            ACOTIZACIÓN
          </Link>
        </>
      </Drawer>
    </>
  );
}

export default NavbarComponent;
