import { Row, Col } from "antd";
import React from "react";
import "./style.css";
import useWindowDimensions from "../getwidthHeight";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";
function SecondSection() {
  const { height, width } = useWindowDimensions();

  return (
    <>
      <NavbarComponent />
      <div className="maindiv">
        {width < 450 ? (
          <Row className="revers">
            <Col lg={16} md={16} xs={24}>
              <div className="maindiv2">
                <div>
                  <h3 className="contact">Contacto</h3>
                </div>
                <div className="inputhol">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input className="input-field" placeholder="NOMBRE" />
                  </div>
                  <div className="holder">
                    <div className="input-container">
                      <label className="input-label"></label>
                      <input className="input-field" placeholder="EMAIL" />
                    </div>
                  </div>
                </div>
                <div className="inputhol1">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input className="input-field" placeholder="TELEFONO" />
                  </div>
                </div>
                <div className="inputhol1">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input className="input-field1" placeholder="MENSAJE" />
                  </div>
                </div>
                <div className="batan">
                  <button className="bton">ENVIAR</button>
                </div>
              </div>
            </Col>
            <Col lg={8} md={6} xs={24}>
              <div className="maindiv1">
                <div className="tele1">
                  <h5 className="tele0">Telefono</h5>
                  <p className="fifty">
                    +52(993) <i className="italic">239</i>
                    <i className="italic">0056</i>
                  </p>
                  <h5 className="tele00">Correo</h5>
                  <p className="fifty">Contacto@grupojl.mx</p>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={8} md={6} xs={24}>
              <div className="maindiv1">
                <div className="tele1">
                  <h5 className="tele0">Telefono</h5>
                  <p className="fifty">
                    +52(993) <i className="italic">239</i>
                    <i className="italic">0056</i>
                  </p>
                  <h5 className="tele00">Correo</h5>
                  <p className="fifty">Contacto@grupojl.com</p>
                </div>
              </div>
            </Col>
            <Col lg={16} md={16} xs={24}>
              <div className="maindiv2">
                <div>
                  <h3 className="contact">Contacto</h3>
                </div>
                <div className="inputhol">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input className="input-field" placeholder="NOMBRE" />
                  </div>
                  <div className="holder">
                    <div className="input-container">
                      <label className="input-label"></label>
                      <input className="input-field" placeholder="EMAIL" />
                    </div>
                  </div>
                </div>
                <div className="inputhol1">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input className="input-field" placeholder="TELEFONO" />
                  </div>
                </div>
                <div className="inputhol1">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input className="input-field1" placeholder="MENSAJE" />
                  </div>
                </div>
                <div className="batan">
                  <button className="bton">ENVIAR</button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <br />
      <br />
      <br />

      <Footer />
    </>
  );
}

export default SecondSection;
