import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import Logoaa from "../../image/Excavadora Volvo.png";
import Logobb from "../../image/Excavadora Komatsu PC450.png";
import Logocc from "../../image/Excavadora Komatsu PC350LC.png";
import Logodd from "../../image/Excavadora Caterpillar 320.jpg";
import Logo333 from "../../image/Excavadora Sany SYH500.png";
import Logo3331 from "../../image/Excavadora Komatsu 500LC.png";

//
import Logoee from "../../image/Excavadora Komatsu PC210LC.png";
import Logogg from "../../image/Excavadora Komatsu PC200LC.png";

import { useSelector } from "react-redux";
import Footer from "../FooterComponent";
import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function TwelveSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);

  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logoaa} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">VOLVO EC210DL</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">167 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARÓN</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1.27 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">22,800 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Excavadoras/Volvo EC210DL.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>

                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("VOLVO EC210DL") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "VOLVO EC210DL",
                                  motor: "167 HP",
                                  cucharón: "1.27 M3",
                                  peso_operativo: "22,800 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logogg} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU PC200LC-8MO</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">155 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARON</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1.2 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">20,900 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Excavadoras/Komatsu PC200LC.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU PC200LC-8MO") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "KOMATSU PC200LC-8MO",
                                  motor: "155 HP",
                                  cucharón: "1.2 M3",
                                  peso_operativo: "20,900 KG",
                                  unidades_disponibles: "2",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logoee} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSUPC210CL-10MO</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">165 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARON</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten" style={{ fontSize: "1rem" }}>
                      1.2 M3 Y 1.4 M3
                    </h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div>
                    <h6 className="poten">20,900 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a href="/pdf/Excavadoras/Komtsu PC210.pdf" target="_blank">
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSUPC210CL-10MO") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "KOMATSUPC210CL-10MO",
                                  motor: "165 HP",
                                  cucharón: "1.2M3Y1.4",
                                  peso_operativo: "20,900 KG",
                                  unidades_disponibles: "2",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logodd} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">CATERPILLAR 320</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">158 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARÓN</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten" style={{ fontSize: "0.8rem" }}>
                      1.19 M3 y 1.4 M3
                    </h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div>
                    <h6 className="poten">22,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Excavadoras/Caterpillar 320.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("CATERPILLAR 320") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "CATERPILLAR 320",
                                  motor: "158 HP",
                                  cucharón: "1.19M3v",
                                  peso_operativo: "22,000 KG",
                                  unidades_disponibles: "4",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logocc} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU PC350LC-8</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">146 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARONES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1.95 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>{/* <h6 className="poten"></h6> */}</div>
                  <div className="power">
                    <h6 className="poten">2.2 M3</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray"></div>
                  <div className="gray1">
                    <h6 className="poten">2.38 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div></div>
                  <div className="power">
                    <h6 className="poten">2.4 M3</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">PESO OPERATIVO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">35,091 KG</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="power1">
                    <h6 className="poten">5</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Excavadoras/Komatsu PC350LC-8-ESP.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU PC350LC-8") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "KOMATSU PC350LC-8",
                                  motor: "146 HP",
                                  cucharón: "1.95 M3",
                                  peso_operativo: "35,091 KG",
                                  unidades_disponibles: "5",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logobb} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU PC450LC-8</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">362 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARON</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2.9 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div>
                    <h6 className="poten">44,320 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Excavadoras/Komatsu PC450_450LC-8.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU PC450LC-8") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "KOMATSU PC450LC-8",
                                  motor: "362 HP",
                                  cucharón: "2.9 M3",
                                  peso_operativo: "44,320 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo333} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">SANY SYH500H</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">300 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARON</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div>
                    <h6 className="poten">44,500 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a href="/pdf/Excavadoras/Sany 500H.pdf" target="_blank">
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("SANY SYH500H") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "SANY SYH500H",
                                  motor: "362 HP",
                                  cucharón: "2.9 M3",
                                  peso_operativo: "44,320 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo3331} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU PC500 LC</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">360 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CUCHARON</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3.05 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div>
                    <h6 className="poten">49,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Excavadoras/Komatsu PC500.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU PC500 LC") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Excavadora",
                                  name: "KOMATSU PC500 LC",
                                  motor: "362 HP",
                                  cucharón: "2.9 M3",
                                  peso_operativo: "44,320 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TwelveSection;
