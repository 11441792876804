import React from "react";
import "./style.css";
import { MailOutlined } from "@ant-design/icons";
import { Row } from "antd";

function Footer(props) {
  return (
    <div className="footermain">
      <div>
        <img src="/imgs/gropo.png" className="logoinfooter" alt="footerlogo" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: props?.width < 450 ? "column" : "row",
        }}
      >
        <div
          style={{
            color: "white",
            textAlign: props?.width < 450 ? "center" : "left",
          }}
        >
          <h5>Teléfono</h5>
          <h5>{"(993) 239 0056"}</h5>
        </div>
        <div
          style={{
            color: "white",
            marginLeft: props?.width < 450 ? "0" : "1rem",
            textAlign: props?.width < 450 ? "center" : "left",
          }}
        >
          <Row>
            <MailOutlined style={{ marginRight: "0.5rem", fontSize: "1rem" }} />

            <h5>Correo</h5>
          </Row>

          <h5 className="contactoffoot">contacto@grupojl.mx</h5>
        </div>
      </div>
    </div>
  );
}

export default Footer;
