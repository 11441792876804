import React from "react";
import NavbarComponent from "../../components/NavbarComponent";
import HeroSection from "../../components/HeroSection";
import Footer from "../../components/FooterComponent";
import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function LandingPage() {
  const { height, width } = useWindowDimensions();

  return (
    <div>
      <NavbarComponent />
      <HeroSection dimensions={{ height, width }} />

      <div style={{ marginTop: "1rem" }}>
        <Footer width={width} />
      </div>
    </div>
  );
}

export default LandingPage;
