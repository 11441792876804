import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandigPage from "./screens/LandingPage";
import SecondSection from "./components/secndSection";
import ThirdSection from "./components/thirdSection";
import FifthSection from "./components/fifthSection";
import SixthSection from "./components/sixthSection";
import SeventhSection from "./components/seventhSection";
import EightSection from "./components/eightSection";
import NinthSection from "./components/ninthSection";
import TenSection from "./components/tenSection";
import EleventhSection from "./components/eleventhSection";
import TwelveSection from "./components/twelveSection";
import FourteenSection from "./components/fourteenSection";
import FifteenSection from "./components/fifteenSection";
import SixteenSection from "./components/sixteenSection";
import SeventeenSection from "./components/seventeenSection";
import EighteenSection from "./components/eighteenSection";
import NineteenSection from "./components/nineteenSection";
import TwentySection from "./components/twentySection";
import TwentyoneSection from "./components/twentyoneSection";
import TwentytwoSection from "./components/twentytwoSection";
import TwentythreeSection from "./components/twentythreeSection";
import TwentyfourSection from "./components/twentyfourSection";
import TwentyfiveSection from "./components/tewntyfiveSection";
import Cart from "./screens/cart";
import Retroexcavadora from "./components/retroExcvadors";
import TwentySix from "./components/twentySix";
import TwentySeven from "./components/twentySeven";
import TwentyEight from "./components/twentyEight";
import TwentyNine from "./components/twentyNine";
import TwentyFive from "./components/twentyFive";
import Transporate from "./components/Transporate";
import Pavimentadoras from "./components/pavim";
import ResiduosPeligrosos from "./components/residuosPeligrosos";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandigPage />,
  },

  {
    path: "/contact",
    element: <SecondSection />,
  },
  {
    path: "/nostros",
    element: <ThirdSection />,
  },

  {
    path: "/machinery-transportation",
    element: <FifthSection />,
  },
  {
    path: "/vision",
    element: <SixthSection />,
  },
  {
    path: "/venta",
    element: <SeventhSection />,
  },
  {
    path: "/politicas",
    element: <EightSection />,
  },
  {
    path: "/CERTIFICACIONES",
    element: <EightSection />,
  },
  {
    path: "/maquinaria",
    element: <NinthSection />,
  },
  {
    path: "/VEHICULOS",
    element: <TenSection />,
  },
  {
    path: "/TRACTOCAMIONES",
    element: <EleventhSection />,
  },
  {
    path: "/Excavadora",
    element: <TwelveSection />,
  },
  {
    path: "/TRACTORES-BULLDOZERS",
    element: <FourteenSection />,
  },
  {
    path: "/VIBROCOMPACTADORES",
    element: <FifteenSection />,
  },
  {
    path: "/PIPAS",
    element: <SixteenSection />,
  },
  {
    path: "/VOLTEOS",
    element: <SeventeenSection />,
  },
  {
    path: "/LOW-BOY",
    element: <EighteenSection />,
  },
  {
    path: "/NineteenSection",
    element: <NineteenSection />,
  },
  {
    path: "/cragdor",
    element: <TwentySection />,
  },
  {
    path: "/GONDOLAS",
    element: <TwentyoneSection />,
  },
  {
    path: "/MOTOCONFORMADORAS",
    element: <TwentytwoSection />,
  },
  {
    path: "/TwentythreeSection",
    element: <TwentythreeSection />,
  },
  {
    path: "/TwentyfourSection",
    element: <TwentyfourSection />,
  },
  {
    path: "/GruaArticulada",
    element: <TwentyfiveSection />,
  },
  {
    path: "/cotizar",
    element: <Cart />,
  },
  {
    path: "/retroexcavadors",
    element: <Retroexcavadora />,
  },
  {
    path: "/ACCESORIOS",
    element: <TwentyFive />,
  },
  {
    path: "/Martillos",
    element: <TwentySix />,
  },
  {
    path: "/TRITURADORAS",
    element: <TwentySeven />,
  },
  {
    path: "/Cribas",
    element: <TwentyEight />,
  },
  {
    path: "/PINZA",
    element: <TwentyNine />,
  },
  {
    path: "/TransporteMaquinariapesada",
    element: <Transporate />,
  },
  {
    path: "/pavimentadoras",
    element: <Pavimentadoras />,
  },
  {
    path: "/residuosPeligrosos",
    element: <ResiduosPeligrosos />,
  },
]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
