import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import "./style.css";
import LogoEE from "../../image/Gondolas.png";
import LogoE from "../../image/fullgondo.jpg";

//
import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function TwentyoneSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={LogoEE} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">GONDOLAS MIRELLES</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAPACIDAD DE CARGA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">
                      37 M<sup>3</sup>
                    </h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">EJES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">EJES RETRACTILES</h6>
                  </div>
                  <div className="power1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">9</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("GONDOLAS MIRELLES G1") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Gondolas",
                                  name: "GONDOLAS MIRELLES G1",
                                  capacidad_de_carga: "37 M^3",
                                  ejes: "3",
                                  ejes_retractiles: "1",
                                  unidades_disponibles: "9",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={LogoEE} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">GONDOLAS MIRELLES</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAPACIDAD DE CARGA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">
                      37 M<sup>3</sup>
                    </h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">EJES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">EJES RETRACTILES</h6>
                  </div>
                  <div className="power1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("GONDOLAS MIRELLES G2") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Gondolas",
                                  name: "GONDOLAS MIRELLES G2",
                                  capacidad_de_carga: "37 M^3",
                                  ejes: "3",
                                  ejes_retractiles: "2",
                                  unidades_disponibles: "3",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={LogoE} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">GONDOLAS FULL</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">MOTOR</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">500 hp</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">Capacidad de carga eje delantero</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">14,600 lb</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">Capacidad de carga eje trasero</h6>
                  </div>
                  <div className="power1">
                    <h6 className="poten">46,000 lb</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">Capacidad de carga</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">
                      30 M<sup>3</sup>
                    </h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="power1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("GONDOLAS MIRELLES G2") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Gondolas",
                                  name: "GONDOLAS MIRELLES G2",
                                  capacidad_de_carga: "37 M^3",
                                  ejes: "3",
                                  ejes_retractiles: "2",
                                  unidades_disponibles: "3",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TwentyoneSection;
