import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import Logo1515 from "../../image/Vibrocompactadora Dynapac CA25.png";
import Logo1414 from "../../image/Vibrocompactaora Volvo SD 105DX.png";
import Logo1510 from "../../image/Vibrocompactadora Hamm 3411.png";
import Logo9898 from "../../image/SANY.png";
import Logo1616 from "../../image/Vibrocompactadora Hamm 311P.jpg";
import Logo1717 from "../../image/Vibrocompactadora Bomag BW 211 D-40.jpg";
import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function FifteenSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1515} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">DYNAPAC CA25</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">140 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,140 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11,600 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Vibrocompactadores/DYNAPAC CA25.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("DYNAPAC CA25") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Vibrocompactadores",
                                  name: "DYNAPAC CA25",
                                  potencia: "140 HP",
                                  ancho_rodillo: "2,140 MM",
                                  peso_operativo: "11,600 KG",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1414} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">VOLVO SD1050X</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">130 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,140 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">10,784 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Vibrocompactadores/VOLVO-sd105dX.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("VOLVO SD1050X") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Vibrocompactadores",
                                  name: "VOLVO SD1050X",
                                  potencia: "130 HP",
                                  ancho_rodillo: "2,140 MM",
                                  peso_operativo: "10,784 KG",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo2" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1510} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">HAMM 3411</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">134 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,140 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11,090 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Vibrocompactadores/HAMM 3411.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("HAMM 3411") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Vibrocompactadores",
                                  name: "HAMM 3411",
                                  potencia: "134 HP",
                                  ancho_rodillo: "2,140 MM",
                                  peso_operativo: "11,090 KG",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo3" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1616} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">HAMM 311P</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">99 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,140 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Vibrocompactadores/Hamm 311.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("HAMM 311P") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Vibrocompactadores",
                                  name: "HAMM 311P",
                                  potencia: "99 HP",
                                  ancho_rodillo: "2,140 MM",
                                  peso_operativo: "11,000 KG",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo4" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1717} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">BOMAG BW 211 D-40</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">102 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,140 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">13,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">16</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Vibrocompactadores/Bomag 211 D-40.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("BOMAG BW 211 D-40") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Vibrocompactadores",
                                  name: "BOMAG BW 211 D-40",
                                  potencia: "102 HP",
                                  ancho_rodillo: "2,140 MM",
                                  peso_operativo: "13,000 KG",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo4" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo9898} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">SANY SSR200C-8H</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">197 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,130 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">20,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a href="/pdf/Vibrocompactadores/SANY.pdf" target="_blank">
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("SANY SSR200C-8H") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Vibrocompactadores",
                                  name: "SANY SSR200C-8H",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FifteenSection;
