import React, { useState, useEffect } from "react";
import "./style.css";
import Logo77 from "../../image/Politicas de gestion.jpeg";
import Ninetwofive from "../../image/ISO390001.png";
import Peoples from "../../image/allpeople.png";
import Fouterrn from "../../image/ISO314001.png";
import { Col, Row, message } from "antd";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";
import JsFileDownloader from "js-file-downloader";

function EightSection() {
  const [isVison, setisVison] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/CERTIFICACIONES") {
      setisVison(true);
    } else {
      setisVison(false);
    }
  }, [window.location.pathname]);
  const handleFileDownload = (fileUrl, fileUrl1) => {
    new JsFileDownloader({
      url: fileUrl,
    })
      .then(function () {
        message.success("Certificate Dowloaded");
      })
      .catch(function (error) {
        message.error("Certificate Dowload Failed");
      });

    new JsFileDownloader({
      url: fileUrl1,
    })
      .then(function () {
        message.success("Certificate Dowloaded");
      })
      .catch(function (error) {
        message.error("Certificate Dowload Failed");
      });
  };
  return (
    <>
      <NavbarComponent />
      {!isVison && (
        <div>
          <Row>
            <Col lg={24} md={24} xs={24}>
              <div className="white">
                <div className="politichol">
                  <h4 className="politic">POLÍTICAS DE GESTIÓN</h4>
                </div>
              </div>
            </Col>
            <Col lg={24} md={24} xs={24}>
              <div className="bgblue">
                <div className="politicpichol">
                  <img className="img-fluid politicpic" src={Logo77} alt="" />
                  <p className="dee">
                    Somos un equipo de profesionales en el arrendamiento,
                    operación y logística de maquinaria y equipo pesado, y en la
                    transportación de agregados pétreos, para la industria de la
                    construcción y el sector energético.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={24} md={24} xs={24}>
              <div className="comprohol">
                <h3 className="compro">NUESTRO COMPROMISO</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              lg={6}
              md={7}
              xs={24}
            >
              <div className="brownhol1">
                <div className="brown">
                  <h4 className="cali">CALIDAD</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    <p>
                      Prestar servicio del más alto nivel de calidad, a través
                      de personal calificado y equipamiento de vanguardia,
                      cumpliendo lo más estrictos estándares del sector y la
                      legislación aplicable en seguridad, salud y protección
                      ambienta.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              lg={6}
              md={7}
              xs={24}
              className="bro"
            >
              <div className="brownhol2">
                <div className="brown">
                  <h4 className="cali">EFICIENCIA</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    <p>
                      Planificar una logistica óptima que garantice el
                      cumplimiento y los requerimientos de nuestros clientes,
                      mediante la optimización, rentabilidad mejora continua de
                      nuestros procesos, agregando valor a su cadena de
                      suministros.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              lg={6}
              md={7}
              xs={24}
            >
              <div className="brownhol3">
                <div className="brown">
                  <h4 className="cali">SOSTENIBILIDAD</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "16px",
                      padding: "0.5rem",
                    }}
                  >
                    <p>
                      Fomentar a creatividad el compromiso social de nuestros
                      colaboradores. con a finalidad de contribuir a su a
                      desarrollo profesional y al logro de nuestros objetivos
                      organizacionales.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <br />
      <br />
      <br />
      <div className="white">
        <div className="politichol">
          <h1 className="politic">CERTIFICACIONES</h1>
        </div>
      </div>

      <Col lg={24} md={24} xs={24}>
        <div style={{ backgroundColor: "#182b49", padding: "2rem" }}>
          <p
            style={{
              fontWeight: 100,
              fontSize: "1.5rem",
              color: "white",
              textAlign: "center",
            }}
          >
            En Grupo JL, estamos comprometidos con la mejora continua y el
            cumplimiento de directrices y objetivos del Sistema de Gestión
            Integral Certificado, el cual es distinguido con las siguientes
            certificaciones:
          </p>
        </div>
      </Col>

      <div style={{ padding: "2rem" }}>
        <Row gutter={15} style={{ marginBottom: "1rem" }}>
          <Col lg={3}>
            <img
              className="img-fluid"
              style={{ width: "7rem" }}
              src="/imgs/2.svg"
              alt=""
            />
          </Col>
          <Col
            lg={18}
            style={{
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Certificación ISO 9001: 2015: Norma Internacional para el Sistema de
            Gestión de Calidad, a través del cual implementamos procesos que
            cumplen con los más estrictos estándares de calidad en el servicio,
            logrando la satisfacción de nuestros clientes.
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }} lg={3}>
            <a
              target="_blank"
              onClick={() => {
                window.open("/9001220151.pdf");
                window.open("/9001220152.pdf");
              }}
            >
              <button
                style={{
                  backgroundColor: "#182b49",
                  padding: "0.5rem",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "1rem",
                  border: "none",
                }}
              >
                VER CERTIFICADOS
              </button>
            </a>
          </Col>
        </Row>

        {/* second certificate */}

        <Row gutter={15} style={{ marginBottom: "1rem" }}>
          <Col lg={3}>
            <img
              className="img-fluid"
              style={{ width: "7rem" }}
              src="/imgs/3.svg"
              alt=""
            />
          </Col>
          <Col
            lg={18}
            style={{
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Certificación ISO 14001:2015: Norma Internacional para el Sistema de
            Gestión Ambiental, a través del cual implementamos acciones y
            controles para la reducción de la contaminación y el control de los
            impactos ambientales generados en nuestras operaciones, así como el
            cumplimiento de la normatividad en material ambiental.
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }} lg={3}>
            <a
              target="_blank"
              onClick={() => {
                window.open("/1400120151.pdf");
                window.open("/1400120152.pdf");
              }}
            >
              <button
                style={{
                  backgroundColor: "#182b49",
                  padding: "0.5rem",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "1rem",
                  border: "none",
                }}
              >
                VER CERTIFICADOS
              </button>
            </a>
          </Col>
        </Row>

        {/* third */}

        <Row gutter={15} style={{ marginBottom: "1rem" }}>
          <Col lg={3}>
            <img
              className="img-fluid"
              style={{ width: "7rem" }}
              src="/imgs/1.svg"
              alt=""
            />
          </Col>
          <Col
            lg={18}
            style={{
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Certificación ISO 45001:2018: Norma Internacional para el Sistema de
            Gestión Seguridad y Salud en el Trabajo, a través del cual nos
            comprometemos a implementar las mejores prácticas de seguridad y
            salud en el trabajo en beneficio de nuestros colaboradores, mediante
            la gestión y control de riesgos identificados, dando cumplimiento a
            la normatividad aplicable.
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }} lg={3}>
            <a
              target="_blank"
              onClick={() => {
                window.open("/4500120181.pdf");
                window.open("/4500120182.pdf");
              }}
            >
              <button
                style={{
                  backgroundColor: "#182b49",
                  padding: "0.5rem",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "1rem",
                  border: "none",
                }}
              >
                VER CERTIFICADOS
              </button>
            </a>
          </Col>
        </Row>

        {/* fourth */}
        <Row gutter={15} style={{ marginBottom: "1rem" }}>
          <Col lg={3}>
            <img
              className="img-fluid"
              style={{ width: "7rem" }}
              src="/imgs/4.svg"
              alt=""
            />
          </Col>
          <Col
            lg={18}
            style={{
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            ISO 37001:2016 Norma Internacional para el Sistema de Gestión Anti
            Soborno con el cual buscamos mejorar los temas en contra del
            soborno, mediante la Identificación y establecimiento de controles,
            así como establecer las barreras de mitigación de riesgos a los
            procesos de la organización y mecanismos seguros de denuncia y
            protocolos seguros de atención ante ella.
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }} lg={3}>
            <a
              target="_blank"
              onClick={() => {
                window.open(
                  "/JLA-2427 CERTIFS. JL LOGISTICA Y AGREGADOS SGAS F-19309, 19310.pdf"
                );
                window.open(
                  "/JMO-2452 CERTIFS. JL MACHINERY SGAS F-19274, 19275.pdf"
                );
              }}
            >
              <button
                style={{
                  backgroundColor: "#182b49",
                  padding: "0.5rem",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "1rem",
                  border: "none",
                }}
              >
                VER CERTIFICADOS
              </button>
            </a>
          </Col>
        </Row>
      </div>

      <div style={{ backgroundColor: "#182b49", padding: "2rem" }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: "2rem",
            color: "white",
            textAlign: "left",
          }}
        >
          EMPRESA SOCIALMENTE RESPONSABLE
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "99vw",
          marginTop: "2rem",
        }}
      >
        <img style={{ width: "90%" }} src={Peoples} />
      </div>
      <div className="textcontainergogo">
        <p style={{ fontSize: "1.4rem" }}>
          JL Grupo ha sido distinguido con el distintivo Empresa Socialmente
          Responsable, dentro de la clasificación de empresa grande, el cual
          reconoce que somos una organización consciente del impacto social,
          económico y ambiental que generamos en nuestro entorno, debido a
          operación de procesos productivos; integrando prácticas éticas y
          sostenibles las cuales producen un impacto positivo en la sociedad.
        </p>
        <p style={{ fontSize: "1.4rem" }}>
          Somos un empresa que asume la responsabilidad de sus acciones,
          considerando el bienestar de las personas, la protección del medio
          ambiente y la generación de valor a largo plazo para todas sus partes
          interesadas
        </p>
      </div>
      <Footer />
    </>
  );
}

export default EightSection;
