import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
// IMG_0688
import Logoken from "../../image/Kenworth Grua Articulada.jpg";
import Logok from "../../image/IMG_0688.jpg";

import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";
function TwentyfiveSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />{" "}
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logoken} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KENWORTH T660</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div>
                    <h6 className="poten">450 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">Capacidad de carga eje delantero</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">14,600 LB</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">Capacidad de carga eie trasero</h6>
                  </div>
                  <div>
                    <h6 className="poten">46,000 LB</h6>
                  </div>
                </div>

                <div className="divo">
                  <h6 className="dd13">GRUA ARTICULADA HIAB XCL23B-4</h6>
                </div>

                <div className="potenhol">
                  <div>
                    <h6 className="poten">Capacidad de carga</h6>
                  </div>
                  <div>
                    <h6 className="poten">6.1 TON</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KENWORTH T660") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "GruaArticulada",
                                  name: "KENWORTH T660",
                                  potencia: "450 HP",
                                  Capacidaddecarga: "6,100 TON",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logok} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KENWORTH T370 2016</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div>
                    <h6 className="poten">315 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">Capacidad de carga eje delantero</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">14,600 LB</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">Capacidad de carga eie trasero</h6>
                  </div>
                  <div>
                    <h6 className="poten">46,000 LB</h6>
                  </div>
                </div>

                <div className="divo">
                  <h6 className="dd13">GRUA ARTICULADA FASSI F155A</h6>
                </div>

                <div className="potenhol">
                  <div>
                    <h6 className="poten">Capacidad de carga</h6>
                  </div>
                  <div>
                    <h6 className="poten">5.8 TON</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KENWORTH T660") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "GruaArticulada",
                                  name: "KENWORTH T660",
                                  potencia: "450 HP",
                                  Capacidaddecarga: "6,100 TON",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />;
    </>
  );
}

export default TwentyfiveSection;
