import React from "react";
import { Row, Col } from "antd";
import "./stylo.css";
import { Link } from "react-router-dom";
import useWindowDimensions from "../getwidthHeight";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";

function TwentyFive() {
  const { width } = useWindowDimensions();

  return (
    <>
      <NavbarComponent />
      <div className="bodytwo">
        <div>
          <Row>
            <Col lg={24} md={24} xs={24}>
              <div className="maqhol">
                <h2 className="maq">ACCESORIOS</h2>
                <h2 className="maq">TRITURACION Y DEMOLICION</h2>
              </div>
            </Col>
          </Row>
          <Row
            style={{ marginLeft: width < 450 ? "0rem" : "3rem" }}
            justify="center"
          >
            <Col className="centro" lg={7} md={10} xs={24}>
              <div className="containerhol">
                <Link to="/Martillos">
                  <div className="image-containerp"></div>
                </Link>
                <h4 className="excava">MARTILLOS</h4>
              </div>
            </Col>
            <Col className="centro" lg={7} md={10} xs={24}>
              <Link to="/TRITURADORAS">
                <div className="image-containerq"></div>
              </Link>

              <h4 className="excava">TRITURADORAS</h4>
            </Col>
            <Col className="centro" lg={7} md={10} xs={24}>
              <Link to="/CRIBAS">
                <div className="image-containerr"></div>
              </Link>
              <h4 className="excava">CRIBAS</h4>
            </Col>
            <Col className="centro" lg={7} md={10} xs={24}>
              <Link to="/PINZA">
                <div className="image-containero"></div>
              </Link>
              <h4 className="excava">PINZAS</h4>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TwentyFive;
