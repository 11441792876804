import React, { useState, useEffect } from "react";
import "./style.css";
import Logo1010 from "../../image/Volteos.jpg";
import Logo10101 from "../../image/Volteo Kenworth T480.jpg";

import Footer from "../FooterComponent";
import { Row, Col } from "antd";

import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function SeventeenSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };
  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div className="border">
        <Row className="rowo" justify="space-between">
          <Col lg={12} md={24} xs={24}>
            <div>
              <img className="bus img-fluid" src={Logo1010} alt="" />
            </div>
          </Col>
          <Col lg={9} md={24} xs={24}>
            <div className="divohol">
              <div className="divo">
                <h6 className="dd13">KENWORTH T370</h6>
              </div>
              <div className="potenhol">
                <div>
                  <h6 className="poten">Motor</h6>
                </div>
                <div className="power">
                  <h6 className="poten">315 hp</h6>
                </div>
              </div>
              <div className="potenhol1">
                <div className="gray">
                  <h6 className="carga">Canacidad de carna pie delantero</h6>
                </div>
                <div className="gray1">
                  <h6 className="poten">14,600 lb</h6>
                </div>
              </div>
              <div className="potenhol">
                <div>
                  <h6 className="poten">Capacidad de carga eje trasero</h6>
                </div>
                <div className="power">
                  <h6 className="poten">46,000 lb</h6>
                </div>
              </div>
              <div className="potenhol1">
                <div className="gray">
                  <h6 className="carga">Capacidad de carga</h6>
                </div>
                <div className="gray1">
                  <h6 className="poten">
                    <sub>14M</sub> 3{" "}
                  </h6>
                </div>
              </div>
              <div className="potenhol">
                <div>
                  <h6 className="poten">UNIDADES DISPONIBLES</h6>
                </div>
                <div className="power1">
                  <h6 className="poten">17 </h6>
                </div>
              </div>
              <div className="btonmng">
                <div>
                  <a href="/pdf/Kenworth T370.pdf" target="_blank">
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </a>
                </div>
                <div>
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      {checkCartIfExist("KENWORTH T370") ? (
                        <p style={{ color: "green" }}>Added!</p>
                      ) : (
                        <button
                          className="btonficha1"
                          onClick={() => {
                            dispatch(
                              updateProductsState({
                                class: "Volteos",
                                name: "KENWORTH T370",
                                motor: "315 hp",
                                canacidad_de_carna_pie_delantero: "14,600 lb",
                                capacidad_de_carga_eje_trasero: "46,000 lb",
                                capacidad_de_carga: "p^3",
                                unidades_disponibles: "17",
                              })
                            );

                            setloading(true);
                            sethitUseEffect(!hitUseEffect);
                          }}
                        >
                          <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="border">
        <Row className="rowo" justify="space-between">
          <Col lg={12} md={24} xs={24}>
            <div>
              <img className="bus img-fluid" src={Logo10101} alt="" />
            </div>
          </Col>
          <Col lg={9} md={24} xs={24}>
            <div className="divohol">
              <div className="divo">
                <h6 className="dd13">KENWORTH T480</h6>
              </div>
              <div className="potenhol">
                <div>
                  <h6 className="poten">Motor</h6>
                </div>
                <div className="power">
                  <h6 className="poten">315 hp</h6>
                </div>
              </div>
              <div className="potenhol1">
                <div className="gray">
                  <h6 className="carga">Canacidad de carna pie delantero</h6>
                </div>
                <div className="gray1">
                  <h6 className="poten">14,600 lb</h6>
                </div>
              </div>
              <div className="potenhol">
                <div>
                  <h6 className="poten">Capacidad de carga eje trasero</h6>
                </div>
                <div className="power">
                  <h6 className="poten">46,000 lb</h6>
                </div>
              </div>
              <div className="potenhol1">
                <div className="gray">
                  <h6 className="carga">Capacidad de carga</h6>
                </div>
                <div className="gray1">
                  <h6 className="poten">
                    <sub>14M</sub> 3{" "}
                  </h6>
                </div>
              </div>
              <div className="potenhol">
                <div>
                  <h6 className="poten">UNIDADES DISPONIBLES</h6>
                </div>
                <div className="power1">
                  <h6 className="poten">5 </h6>
                </div>
              </div>
              <div className="btonmng">
                <div>
                  <a
                    href="https://www.kenworth.com/media/jorfecyl/t380-t480-single-page-flyer-04-15-2021.pdf"
                    target="_blank"
                  >
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </a>
                </div>
                <div>
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      {checkCartIfExist("KENWORTH T480") ? (
                        <p style={{ color: "green" }}>Added!</p>
                      ) : (
                        <button
                          className="btonficha1"
                          onClick={() => {
                            dispatch(
                              updateProductsState({
                                class: "Volteos",
                                name: "KENWORTH T480",
                                motor: "315 hp",
                                canacidad_de_carna_pie_delantero: "14,600 lb",
                                capacidad_de_carga_eje_trasero: "46,000 lb",
                                capacidad_de_carga: "p^3",
                                unidades_disponibles: "5",
                              })
                            );

                            setloading(true);
                            sethitUseEffect(!hitUseEffect);
                          }}
                        >
                          <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default SeventeenSection;
