import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import LogoGO from "../../image/Retroexcavadora Komatsu WB 93p.png";
import Footer from "../FooterComponent";
import Retro1 from "../../image/Retroexcavadora Caterpillar 416.png";
import Retro2 from "../../image/416con.jpg";

import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function Retroexcavadora() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Retro2} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">CATERPILLAR 416</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">92.53 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CAPACIDAD BOTE FRONTAL</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES CABINA CERRADA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Retroexcavadoras/Caterpillar 416.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("CATERPILLAR 416") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Retroexcavadors",
                                  name: "CATERPILLAR 416",
                                  potencia: "92.53 HP",
                                  capacidad_bote_frontal: "1 M3",
                                  peso_operativo: "11,000 KG",
                                  unidades_disponibles: "6",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Retro1} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">CATERPILLAR 416</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">92.53 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CAPACIDAD BOTE FRONTAL</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES CABINA ABIERTA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Retroexcavadoras/Caterpillar 416.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("CATERPILLAR 416 con") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Retroexcavadors",
                                  name: "CATERPILLAR 416 con",
                                  potencia: "92.53 HP",
                                  capacidad_bote_frontal: "1 M3",
                                  peso_operativo: "11,000 KG",
                                  unidades_disponibles: "6",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={LogoGO} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU WB93P</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">92.24 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CAPACIDAD BOTE FRONTAL</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">9,000 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">9</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Retroexcavadoras/Komatsu 93R.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU WB93P") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Retroexcavadors",
                                  name: "KOMATSU WB93P",
                                  potencia: "92.24 HP",
                                  capacidad_bote_frontal: "1 M3",
                                  peso_operativo: "9,000 KG",
                                  unidades_disponibles: "5",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Retroexcavadora;
