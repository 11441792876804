import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import Logovihi from "../../image/Toyota_Hilux_Doble_Cabina-removebg-preview.png";
import Logocar from "../../image/Toyota_Hilux_Chasis-removebg-preview.png";
import Logovihi1 from "../../image/Renault_Oroch-removebg-preview (1).png";
import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function TenSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div className="bodyy">
        <Row>
          <Col lg={7} md={18} xs={24}>
            <div>
              <h3 className="vehi">
                Vehículos
                <br />
                Utilitarios
              </h3>
            </div>
          </Col>
          <Col lg={15} md={22} xs={24}>
            <div className="car">
              <div>
                <img className="img-fluid cari" src={Logovihi} alt="" />
              </div>
              <div className="barihol">
                <div className="bari">
                  <h6 className="toyo">TOYOTA HILUX DOBLE CABINA</h6>
                </div>

                <div className="bttnhol1">
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      {checkCartIfExist("TOYOTA HILUX DOBLE CABINA") ? (
                        <p style={{ color: "green" }}>Added!</p>
                      ) : (
                        <button
                          className="bttn"
                          onClick={() => {
                            dispatch(
                              updateProductsState({
                                class: "Vehiculos",
                                name: "TOYOTA HILUX DOBLE CABINA",
                              })
                            );

                            setloading(true);
                            sethitUseEffect(!hitUseEffect);
                          }}
                        >
                          <h6 className="teni">AÑADIR A COTIZACIÓN</h6>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="alyy" justify="center">
          <Col lg={12} md={18} xs={24}>
            <div className="car">
              <div className="cari1hol">
                <img className="img-fluid cari1" src={Logocar} alt="" />
              </div>
              <div className="alli">
                <div className="bari1hol">
                  <div className="bari100">
                    <h6 className="toyo">TOYOTA HILUX CHASSIS</h6>
                  </div>
                </div>
                <div className="bttnhol1">
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      {checkCartIfExist("TOYOTA HILUX CHASSIS") ? (
                        <p style={{ color: "green" }}>Added!</p>
                      ) : (
                        <button
                          className="bttn"
                          onClick={() => {
                            dispatch(
                              updateProductsState({
                                class: "Vehiculos",
                                name: "TOYOTA HILUX CHASSIS",
                              })
                            );

                            setloading(true);
                            sethitUseEffect(!hitUseEffect);
                          }}
                        >
                          <h6 className="teni">AÑADIR A COTIZACIÓN</h6>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={11} md={18} xs={24}>
            <div className="car">
              <div className="cari1hol">
                <img className="img-fluid cari1" src={Logovihi1} alt="" />
              </div>
              <div className="alli">
                <div className="bari1hol">
                  <div className="bari100">
                    <h6 className="toyo">RENAULT ARCH LIFE</h6>
                  </div>
                </div>
                <div className="bttnhol1">
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      {checkCartIfExist("RENAULT ARCH LIFE") ? (
                        <p style={{ color: "green" }}>Added!</p>
                      ) : (
                        <button
                          className="bttn"
                          onClick={() => {
                            dispatch(
                              updateProductsState({
                                class: "Vehiculos",
                                name: "RENAULT ARCH LIFE",
                              })
                            );

                            setloading(true);
                            sethitUseEffect(!hitUseEffect);
                          }}
                        >
                          <h6 className="teni">AÑADIR A COTIZACIÓN</h6>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default TenSection;
