import React, { useState, useEffect } from "react";
import "./style.css";
import { Col, Row } from "antd";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";

import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function Transporate() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <Row justify="center" className="hills">
          <Col lg={7} md={24} xs={24}>
            <div className="ventahol">
              <h5
                className="venta1"
                style={{ textAlign: "center", width: "22rem" }}
              >
                TRANSPORTE DE
              </h5>
              <h5
                className="venta1"
                style={{ textAlign: "center", width: "22rem" }}
              >
                MAQUINARIA PESADA
              </h5>
              <p
                style={{
                  textAlign: "start",
                }}
                className="con"
              >
                En JL LOGISTICA, estamos en una misión constante de excelencia
                en el trasporte de maquinaria. Nuestro compromiso se basa en la
                dedicación total a la satisfacción del cliente y en la promesa
                de entregar su maquinaria en las mejores condiciones. Nuestra
                mentalidad de mejora continua nos impulsa hacia adelante.
              </p>
            </div>
            <div className="coti-hol">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <>
                  {checkCartIfExist("TRANSPORTE DE MAQUINARIA PESADA") ? (
                    <p style={{ color: "green" }}>Added!</p>
                  ) : (
                    <button
                      className="bton-coti"
                      onClick={() => {
                        dispatch(
                          updateProductsState({
                            class:
                              "TRANSPORTANDO TU MAQUINARIA CONPRECISION Y EFICIENCIA",
                            name: "TRANSPORTE DE MAQUINARIA PESADA",
                          })
                        );

                        setloading(true);
                        sethitUseEffect(!hitUseEffect);
                      }}
                    >
                      <h6 className="teni">COTIZAR</h6>
                    </button>
                  )}
                </>
              )}
            </div>
          </Col>
          <Col style={{ height: "90vh" }} lg={16} md={24} xs={24}>
            <div className="hope1">
              <div className="bluebarhol1">
                <p className="textbluehol">
                  TRANSPORTANDO TU MAQUINARIA CON
                  <br />
                  PRECISION Y EFICIENCIA
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default Transporate;
