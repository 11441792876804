import { store } from "../store";

const checkCartIfExist = (name) => {
  const products = store.getState().profile.products;

  const found = products.some((el) => el.name === name);

  return found;
};

export { checkCartIfExist };
