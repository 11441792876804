import { Col, Row } from "antd";
import "./style.css";
import Logo1 from "../../image/JL LOGISTIC-27.png";
import Logo2 from "../../image/JL LOGISTIC-28.png";
import Logo3 from "../../image/JL LOGISTIC-29.png";
import React from "react";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";
import Sixthsection from "../sixthSection";

function ThirdSection() {
  return (
    <>
      <div>
        <NavbarComponent />
        <div className="body111">
          <div className="maindivofnostro">
            <h1 className="noso">QUIENES SOMOS</h1>
            <h4 className="childnoso">
              Somos un grupo de empresas con presencia en el sureste mexicano
              que ofrece renta de maquinaria y equipos certificados, transporte
              de carga y servicios especializados para la industria de la
              construcción ejecutando proyectos de infraestructura, caminos y
              obra civil.
            </h4>
            <h1 className="child">NUESTRO GRUPO</h1>
            <h4 className="childnoso">
              Grupo JL opera actualmente a través de 2 empresas, las cuales dan
              cobertura y expansión de nuestros servicios a diversas regiones
              del país.
            </h4>
            <Row justify="center">
              <Col className="columofantd" lg={8} md={8} xs={24}>
                <div className="imghol">
                  <img className="img-fluid jl" src={Logo1} alt="" />
                  <p className="empresa1">
                    Empresa especializada en renta de maquinaria pesada como:
                    vibrocompactadores, retroexcavadoras. excavadoras,
                    motoconformadoras. tractores bulldozers, dragas de arrastre,
                    dragas de succión y servicios de asesoria para la industria
                    de la construcción.
                  </p>
                </div>
              </Col>
              <Col className="columofantd" lg={8} md={8} xs={24}></Col>
              <Col className="columofantd" lg={8} md={8} xs={24}>
                <div className="imghol">
                  <img className="img-fluid jl1" src={Logo2} alt="" />
                  <p className="empresa1">
                    Empresa dedicada a la logística y el transporte de agregados
                    pétreos y maquinaria pesada, producción y comercialización
                    de agregados pétreos y renta de equipos como camiones
                    volteo. camiones pipa y tractocamiones
                  </p>
                </div>
              </Col>
            </Row>
            <div style={{ marginTop: "10rem" }}>
              <h1 className="noso">SERVICIOS</h1>

              <h4 style={{ color: "white", fontWeight: 100 }}>
                •Arrendamiento, operación y venta de maquinaria y equipo pesado.
              </h4>
              <h4
                style={{ color: "white", fontWeight: 100, marginTop: "3rem" }}
              >
                •Servicio público de autotransporte federal de carga general,
                carga especializada en sus diversas modalidades como:
                materiales, residuos, remanentes o desechos peligrosos y de
                manejo especial, objetos voluminosos y/o de gran peso,
                maquinaria, grúas y equipos especializados.
              </h4>
            </div>
          </div>
        </div>
      </div>

      <Sixthsection />
      <Footer />
    </>
  );
}

export default ThirdSection;
